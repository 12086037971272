<template>
  <el-menu :router="true" :default-active="currentMenu" :unique-opened="true" class="el-menu-vertical-demo" 
  text-color="rgba(255,255,255,0.8)" active-text-color="rgba(255,255,255,1)"  :default-openeds="defaultOpeneds"
  @open="handleOpen" @close="handleClose" @select="handleselect" :collapse="isCollapse" :collapse-transition="false">
    <!-- logo -->
    <div class="logo flex-all">
    	<!-- <i class="icon-cube3 fs_24"></i> -->
		<img src="../../assets/images/bkq-logo.png" alt="" style="width: 30px;height: 30px;">
    	<span class="font-lg ml-1 font-weight-bold" v-show="!isCollapse">{{config.shop_name || ''}}</span>
    </div>
    <!-- 为了做递归，所以把Menu抽出来做成一个组件 -->
    <Menu :menuDatas="menuDatas"></Menu>

<!-- 	    <el-menu-item :index="item.path" v-for="(item,index) in menuDatas" :key="index" v-if="item&&item.sub==undefined || item&&item.sub===null || item&&item.sub.length===0">
		   <i class="el-icon-document"></i>
		   <span slot="title">{{item.title}}</span>
		 </el-menu-item>
		 <el-submenu :index="item.path" v-else>
				 <template slot="title">
				   <i class="el-icon-document-copy"></i>
				   <span>{{item.title}}</span>
				 </template>
				 <el-menu-item-group v-for="(val,key) in item.sub" :key="key">
					  <el-menu-item :index="val.path">
						  <i :class="val.icon ? val.icon : 'el-icon-document'"></i>
						  <span slot="title">{{val.title}}</span>
					  </el-menu-item>
				 </el-menu-item-group>
		 </el-submenu> -->
	   

  </el-menu>
</template>

<script>
  import { mapGetters } from 'vuex'
  import Menu from './Menu.vue'
  import { sideBarData } from './sideBarData.js'
  import { fixedData } from './fixedMenu.js'

  export default {
    provide() {// 向兄弟组件传递重载菜单的方法
      return{
        reloadMenu: this.loadMenu
      }
    },
	props: {
	  isCollapse: {
	    default: false,
	    type: Boolean
	  },
	},
    name:"SideBar",
		data () {
			return {
				currentMenu: 'index', // SideBar里面当前高亮菜单的默认值
				menuDatas: [],
				// isCollapse:false,
				defaultOpeneds:[],
			}
		},
		components: {
			Menu
		},
		computed: {
           ...mapGetters([
                 'config'
           ])
		},
		created () {
			this.setCurrentMenu(); // 刷新时，高亮菜单
            // this.mediaCollapse();
		},
        inject: ['reload'], // 注入重载的功能（注入依赖
		watch: {
	    $route(to, from) {
	      this.setCurrentMenu(); // 监听路由切换时，高亮菜单
        //this.reload();
	    },
	  },
      mounted(){
		  this.loadMenu();
		 //  window.onresize = () => {
			// return (() => {
			//   this.mediaCollapse();
			// })();
		 //  };
		 //  this.mediaCollapse();
    },
	methods:{
      // 加载菜单
      loadMenu(){
		  this.axios.post('/manage/operation/menus',{
			  token: this.$cookies.get("token")
		  }).then(res=>{
			  if(res.status){
				  var fixedMenu = fixedData.menu;
				  this.menuDatas = [...fixedMenu,...res.data];
			  }
		  })
      },
      // 高亮菜单
      setCurrentMenu(){
        var path = this.$route.path.split('#');
        var pathArr = path[0].split('/');
        var num = pathArr.length;
        if(num<4){ // 二级页面
          this.currentMenu = path[0];
        }else{ // 三级页面
          this.currentMenu = pathArr.slice(0,pathArr.length-1).join("/");
        }
      },
	  	handleOpen(key, keyPath) {
        // console.log(key, keyPath);
      },
      handleClose(key, keyPath) {
        // console.log(key, keyPath);
      },
      handleselect(key, keyPath){
        if(key == '/index'){
          this.defaultOpeneds = [];
        }
        this.reload();  // 点击侧边栏重新载入页面
      },
      // toggleCollapse(){
      //   this.isCollapse = !this.isCollapse;
      // },
      // mediaCollapse(){
      //   let screenW = document.body.clientWidth;
      //   if(screenW < 1440){
      //     this.isCollapse = true;
      //   }else{
      //     this.isCollapse = false;
      //   }
      // }
	  },
	}
</script>
<style>
.sidebarLogoFade-enter-active {
  transition: opacity 1.5s;
}

.sidebarLogoFade-enter,
.sidebarLogoFade-leave-to {
  opacity: 0;
}
</style>